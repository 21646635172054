import * as React from "react";
import * as styles from "./demo-page.module.css"
import "../i18n";
import Layout from "../components/Layout/Layout";
import {useTranslation} from "react-i18next";
import TrialHeader from "../components/TrialHeader/TrialHeader";
import TrialFeatures from "../components/TrialFeatures/TrialFeatures";
import Testimonial from "../components/Testimonial/Testimonial";
import TrialFunctionalities from "../components/TrialFunctionalities/TrialFunctionalities";
import TrialSimplePricing from "../components/TrialSimplePricing/TrialSimplePricing";
import StartButton from "../components/Start-Button/Start-button";
import logo from "../images/logo.svg"

export default function BookDemo() {
    const {t} = useTranslation();
    const testimonials = Array.from(t("trial-demo-page.testimonials_section.testimonials"));

    return (
        <Layout showNavbar={false} showFooter={false}>
            <div className={styles.logoSection}>
                <img src={logo} alt={"Hiringmaster logo"}/>
            </div>
            <TrialHeader textButton={t("trial-demo-page.book_demo")} actionButton={t("demo_form")}
                         tickText={t('strings_tag')}/>

            <TrialFeatures textButton={t("trial-demo-page.book_demo")} actionButton={t("demo_form")}/>
            <div className={styles.testimonial}>
                <Testimonial
                    text={testimonials[0].message}
                    avatar={testimonials[0].photo}
                    name={testimonials[0].author}
                    position={testimonials[0].position}
                />
            </div>
            <TrialFunctionalities/>
            <TrialSimplePricing isTrial={false}/>
            <div className={styles.testimonial}>
                <Testimonial
                    text={testimonials[1].message}
                    avatar={testimonials[1].photo}
                    name={testimonials[1].author}
                    position={testimonials[1].position}
                />
            </div>
            <div className={styles.demoSection}>
                <div className={styles.demoTexts}>
                    <h1 className={styles.demoTitle}>{t('trial-demo-page.demo_hiringmaster.title')}</h1>
                    <p className={styles.demoDescription}>{t('trial-demo-page.demo_hiringmaster.subtitle')}</p>
                </div>
                <div className={styles.demoActionContainer}>
                    <StartButton text={t("trial-demo-page.book_demo")}
                                 to={t("demo_form")}
                                 filled={true}
                                 chevron={true}
                                 newTab={true}
                                 filedColor={'#30BF78'}/>
                    <p className={styles.demoActionTag}>{t('trial-demo-page.demo_hiringmaster.tag')}</p>
                </div>
            </div>
        </Layout>
    );
}
